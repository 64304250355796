import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'
import { Container } from 'reactstrap'
import { connect } from 'react-redux'
import * as loadingActions from '../../../state/actions/loading'

import Config from '../../../config/Config.json'
import Config_local from '../../../config/Config_local.json'

import SportsPassForm from "./../Form/SportsPassForm"
import SportsPassFilterReset from './../Form/SportsPassFilterReset';

import SportsPassBreadcrumb from './../SportsPassBreadcrumb'
import SportsPassListItem from './SportsPassListItem'

import Pagination from "../../Pagination/Pagination"

const config = { ...Config, ...Config_local }

class SportsPassList extends Component {
	_isMounted = false

	constructor(props, context) {
		super(props, context)
		this.state = {
			mysearch: '',
			SportsPassFilterFilterActiveTypes: '',
			SportsPassFilterFilterActiveActivities: '',
			SportsPassFilterFilterActiveAges: '',
			SportsPassFilterFilterActiveLevels: '',
			SportsPassFilterFilterActiveDays: '',
			SportsPassFilterFilterActiveNotFull: false,
			SportPassShowMap: false,
			SportsPassResetShow: false,
			posts: 0,
			page: localStorage.getItem('BonsPlanJeunesFilterPage') || 0,
		}
	}

	componentDidMount() {
		this._isMounted = true
		const { loadingOn } = this.props
		loadingOn('SportsPassList')
		this.setState({
			mysearch: localStorage.getItem('SportsPassFilterMySearch') || '',
			SportsPassFilterFilterActiveTypes: localStorage.getItem('SportsPassFilterFilterActiveTypes') || '',
			SportsPassFilterFilterActiveActivities: localStorage.getItem('SportsPassFilterFilterActiveActivities') || '',
			SportsPassFilterFilterActiveAges: localStorage.getItem('SportsPassFilterFilterActiveAges') || '',
			SportsPassFilterFilterActiveLevels: localStorage.getItem('SportsPassFilterFilterActiveLevels') || '',
			SportsPassFilterFilterActiveDays: localStorage.getItem('SportsPassFilterFilterActiveDays') || '',
			SportsPassFilterFilterActiveNotFull: localStorage.getItem('SportsPassFilterFilterActiveNotFull') || false,
			SportsPassResetShow: localStorage.getItem('SportsPassFilterMySearch') ||
				localStorage.getItem('SportsPassFilterFilterActiveTypes') ||
				localStorage.getItem('SportsPassFilterFilterActiveActivities') ||
				localStorage.getItem('SportsPassFilterFilterActiveAges') ||
				localStorage.getItem('SportsPassFilterFilterActiveLevels') ||
				localStorage.getItem('SportsPassFilterFilterActiveDays') ||
				localStorage.getItem('SportsPassFilterFilterActiveNotFull') ||
				false,
		}, () => {
			this.launchSearch()
		})
	}

	componentWillUnmount() {
		const { loadingOff } = this.props
		this._isMounted = false
		loadingOff('SportsPassList')
	}

	// componentDidUpdate(prevProps, prevState, snapshot) {
	// 	const {loadingOn} = this.props
	// 	if(prevState.mysearch !== this.state.mysearch
	// 		|| prevState.page !== this.state.page
	// 		|| prevState.SportsPassFilterFilterActiveTypes !== this.state.SportsPassFilterFilterActiveTypes
	// 		|| prevState.SportsPassFilterFilterActiveActivities !== this.state.SportsPassFilterFilterActiveActivities
	// 	){
	// 		loadingOn('SportsPassList')
	// 		this.launchSearch()
	// 	}
	// }

	launchSearch = () => {
		const page = ((this.state.page - 1) >= 0 ? ((this.state.page - 1) * 12) : 0)
		let queryOpenData = '?where=';
		let where = '1=1';
		if (this.state.mysearch && this.state.mysearch.length > 0) {
			where = where + ' AND description LIKE \'%25' + encodeURI(this.state.mysearch) + '%25\'';
		}
		if (this.state.SportsPassFilterFilterActiveTypes && this.state.SportsPassFilterFilterActiveTypes.length > 0) {
			where = where + ' AND type_sport=\'' + encodeURI(this.state.SportsPassFilterFilterActiveTypes) + '\'';
		}
		if (this.state.SportsPassFilterFilterActiveActivities && this.state.SportsPassFilterFilterActiveActivities.length > 0) {
			where = where + ' AND activite=\'' + encodeURI(this.state.SportsPassFilterFilterActiveActivities).replace('+', '%2B').replace('%20', '+') + '\'';
		}
		if (this.state.SportsPassFilterFilterActiveAges && this.state.SportsPassFilterFilterActiveAges.length > 0) {
			where = where + ' AND tranche_age=\'' + encodeURI(this.state.SportsPassFilterFilterActiveAges).replace('+', '%2B').replace('%20', '+') + '\'';
		}
		if (this.state.SportsPassFilterFilterActiveLevels && this.state.SportsPassFilterFilterActiveLevels.length > 0) {
			where = where + ' AND niveau=\'' + encodeURI(this.state.SportsPassFilterFilterActiveLevels) + '\'';
		}
		if (this.state.SportsPassFilterFilterActiveDays && this.state.SportsPassFilterFilterActiveDays.length > 0) {
			where = where + ' AND jour=\'' + encodeURI(this.state.SportsPassFilterFilterActiveDays) + '\'';
		}
		if (this.state.SportsPassFilterFilterActiveNotFull === true) {
			where = where + ' AND creneau=\'OUVERT\''
		}
		queryOpenData = queryOpenData + where + '&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&distance=&units=esriSRUnit_Foot&relationParam=&outFields="*"&returnGeometry=true&maxAllowableOffset=&geometryPrecision=&outSR=&havingClause=&gdbVersion=&historicMoment=&returnDistinctValues=false&returnIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&returnZ=false&returnM=false&multipatchOption=xyFootprint&resultOffset=&resultRecordCount=&returnTrueCurves=false&returnExceededLimitFeatures=false&quantizationParameters=&returnCentroid=false&timeReferenceUnknownClient=false&sqlFormat=none&resultType=&featureEncoding=esriDefault&datumTransformation='
		fetch('https://carto.ville-roubaix.fr/server/rest/services/OpenData/SPORTS/FeatureServer/4/query' + queryOpenData + '&f=json',
			{
				headers: {},
				method: "GET"
			}
		).then(response => {
			if (response.status && response.status > 200) {
				console.warn(response)
			} else {
				return response.json()
			}
		}).then(results => {
			const { nhits, offset, limit, features } = results
			const { loadingOff } = this.props

			this._isMounted && this.setState({
				totals: nhits,
				offset,
				limit,
				page: localStorage.getItem('SportsPassFilterPage') || 0,
				posts: features
			})
			this.SportsPassOnPaginateScroll()
			loadingOff('SportsPassList')
		}).catch(error => console.warn("error", error))
	}

	SportsPassOnKeyDown = ({ target }) => {
		if (!this.onKeyDown) {
			this.onKeyDown = true
		}
	}

	SportsPassOnKeyUp = ({ keyCode, target }) => {
		this.onKeyDown = false
		if (keyCode === 13) {
			this.launchSearch()
		} else {
			const { value } = target
			this.setState({
				mysearch: value.trim()
			}, () => {
				localStorage.setItem('SportsPassFilterMySearch', value.trim())
				this.launchSearch()
			})
		}
	}

	SportsPassOnChangeFilterTypes = (val) => {
		this.setState({
			SportsPassFilterFilterActiveTypes: val.trim(),
			SportsPassResetShow: true,
			page: 0
		}, () => {
			localStorage.setItem('SportsPassFilterFilterActiveTypes', val.trim());
			localStorage.setItem('SportsPassFilterPage', 0);
			localStorage.setItem('SportsPassResetShow', true);
			this.launchSearch()
		})
	}

	SportsPassOnChangeFilterActivities = (val) => {
		this.setState({
			SportsPassFilterFilterActiveActivities: val.trim(),
			SportsPassResetShow: true,
			page: 0
		}, () => {
			localStorage.setItem('SportsPassFilterFilterActiveActivities', val.trim());
			localStorage.setItem('SportsPassFilterPage', 0);
			localStorage.setItem('SportsPassResetShow', true);
			this.launchSearch()
		})
	}

	SportsPassOnChangeFilterAges = (val) => {
		this.setState({
			SportsPassFilterFilterActiveAges: val.trim(),
			SportsPassResetShow: true,
			page: 0
		}, () => {
			localStorage.setItem('SportsPassFilterFilterActiveAges', val.trim());
			localStorage.setItem('SportsPassFilterPage', 0);
			localStorage.setItem('SportsPassResetShow', true);
			this.launchSearch()
		})
	}

	SportsPassOnChangeFilterLevels = (val) => {
		this.setState({
			SportsPassFilterFilterActiveLevels: val.trim(),
			SportsPassResetShow: true,
			page: 0
		}, () => {
			localStorage.setItem('SportsPassFilterFilterActiveLevels', val.trim());
			localStorage.setItem('SportsPassFilterPage', 0);
			localStorage.setItem('SportsPassResetShow', true);
			this.launchSearch()
		})
	}

	SportsPassOnChangeFilterNotFull = (val) => {
		this.setState({
			SportsPassFilterFilterActiveNotFull: !this.state.SportsPassFilterFilterActiveNotFull,
			SportsPassResetShow: true,
			page: 0
		}, () => {
			localStorage.setItem('SportsPassFilterFilterActiveNotFull', this.state.SportsPassFilterFilterActiveNotFull);
			localStorage.setItem('SportsPassFilterPage', 0);
			localStorage.setItem('SportsPassResetShow', true);
			this.launchSearch()
		})
	}

	SportsPassOnChangeFilterDays = (val) => {
		this.setState({
			SportsPassFilterFilterActiveDays: val.trim(),
			SportsPassResetShow: true,
			page: 0
		}, () => {
			localStorage.setItem('SportsPassFilterFilterActiveDays', val.trim());
			localStorage.setItem('SportsPassFilterPage', 0);
			localStorage.setItem('SportsPassResetShow', true);
			this.launchSearch()
		})
	}

	SportsPassOnSubmit = (e) => {
		e.preventDefault()
		this.launchSearch()
	}

	SportsPassOnClickPaginate = (e) => {
		e.preventDefault()
		this.setState({
			page: e.target.getAttribute('data-num-page')
		}, () => {
			localStorage.setItem('SportsPassFilterPage', this.state.page);
			this.SportsPassOnPaginateScroll()
			this.launchSearch()
		})
	}


	SportsPassOnPaginateScroll = () => {
		if (typeof window !== 'undefined') {
			window.scroll(0, document.querySelector(".sports-pass-filters").getBoundingClientRect().top)
			window.scroll(0, document.querySelector(".sports-pass-filters").getBoundingClientRect().top)
		}
	}

	SportsPassOnReset = (e) => {
		e.preventDefault()
		this.setState({
			mysearch: '',
			SportsPassFilterFilterActiveTypes: '',
			SportsPassFilterFilterActiveActivities: '',
			SportsPassFilterFilterActiveAges: '',
			SportsPassFilterFilterActiveLevels: '',
			SportsPassFilterFilterActiveDays: '',
			SportsPassFilterFilterActiveNotFull: false,
			posts: 0,
			page: 0,
			SportsPassResetShow: false,
		}, () => {
			localStorage.setItem('SportsPassFilterMySearch', '');
			localStorage.setItem('SportsPassFilterFilterActiveTypes', '');
			localStorage.setItem('SportsPassFilterFilterActiveActivities', '');
			localStorage.setItem('SportsPassFilterFilterActiveAges', '');
			localStorage.setItem('SportsPassFilterFilterActiveLevels', '');
			localStorage.setItem('SportsPassFilterFilterActiveDays', '');
			localStorage.setItem('SportsPassFilterFilterActiveNotFull', false);
			localStorage.setItem('SportsPassFilterPage', 0);
			localStorage.setItem('SportsPassResetShow', false);
			this.launchSearch()
		})
	}

	SportsPassOnClickShowMap = (e) => {
		e.preventDefault()
		this.setState({
			SportPassShowMap: !this.state.SportPassShowMap
		})
	}

	render() {
		const { posts, totals, page } = this.state
		return (
			<div>
				<Container>
					<Helmet>
						<title>Annuaire Sport Pass</title>
					</Helmet>
					<div className="offline-fallback-fix-no-idea-why"></div>
					<SportsPassBreadcrumb />
					<div id="sports-pass" className="sports-pass">
						<SportsPassForm
							SportsPassFilterMySearch={this.state.mysearch}
							SportsPassFilterFilterActiveTypes={this.state.SportsPassFilterFilterActiveTypes}
							OnChangeFilterTypes={this.SportsPassOnChangeFilterTypes}
							SportsPassFilterFilterActiveActivities={this.state.SportsPassFilterFilterActiveActivities}
							OnChangeFilterActivities={this.SportsPassOnChangeFilterActivities}
							SportsPassFilterFilterActiveAges={this.state.SportsPassFilterFilterActiveAges}
							OnChangeFilterAges={this.SportsPassOnChangeFilterAges}
							SportsPassFilterFilterActiveLevels={this.state.SportsPassFilterFilterActiveLevels}
							OnChangeFilterLevels={this.SportsPassOnChangeFilterLevels}
							SportsPassFilterFilterActiveDays={this.state.SportsPassFilterFilterActiveDays}
							OnChangeFilterDays={this.SportsPassOnChangeFilterDays}
							SportsPassFilterFilterActiveNotFull={this.state.SportsPassFilterFilterActiveNotFull}
							OnChangeFilterNotFull={this.SportsPassOnChangeFilterNotFull}
							OnClickShowMap={this.SportsPassOnClickShowMap}
							SportPassShowMap={this.state.SportPassShowMap}
							OnSubmit={this.SportsPassOnSubmit}
							placeholder="Chercher un sport / une salle"
							type="text"
							onKeyDown={this.SportsPassOnKeyDown}
							onKeyUp={this.SportsPassOnKeyUp}
						/>

						<h1 className="sports-pass-result">
							{posts.length ?
								`${posts.length} résultat${posts.length > 1 ? 's' : ''} pour votre recherche ${this.state.mysearch.trim().length > 0 ? `«${this.state.mysearch}»` : ''}` :
								`Pas de résultat pour votre recherche  ${this.state.mysearch.trim().length > 0 ? `«${this.state.mysearch}»` : ''}`
							}
						</h1>
						<SportsPassFilterReset SportsPassOnReset={this.SportsPassOnReset} SportsPassResetShow={this.state.SportsPassResetShow} />
						<ul id="sports-pass-list" className="sports-pass-list list-unstyled">
							{posts && posts.length > 0 && posts.map((post, index) =>
								<>
									<SportsPassListItem
										postIndex={index}
										postItem={post.attributes}
									/>
									{post.attributes.equipements}
								</>
							)}
							{posts && !posts.length > 0 && Array(4).fill('').map((u, index) => <li className="agenda-list-item" key={index} />)}
						</ul>

						<div className="sports-pass-list-footer container">
							<div className="row">
								<div className="col-sm d-flex justify-content-start">
									<p className="generic-filter-button sports-pass-filter-button"><Link className="btn btn-primary btn-lg btn-pink-3" to="/services-infos-pratiques/sports/pratiquer-un-sport-de-detente/">Inscription et Tarifs</Link></p>
								</div>
								<div className="col-sm d-flex align-items-center">
									<Pagination
										offset={config.sportsPassPagination.offset}
										limit={config.sportsPassPagination.limit}
										totals={posts.length}
										currentPage={page}
										OnClickPaginate={this.SportsPassOnClickPaginate}
									/>
								</div>
								<div className="col-sm"></div>
							</div>
						</div>
					</div>
				</Container>
			</div>
		)
	}
}

export default connect(null, loadingActions)(SportsPassList)